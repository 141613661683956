import React, { useState } from "react";
import PlaybookforContractors from "../../components/_Landing/AutodeskLanding";
import SEO from "../../components/Seo";

const Services = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <PlaybookforContractors />;
};

export default Services;

export const Head = ({ location }) => (
  <SEO
    title={"BIM Services | Revit Services in Singapore"}
    description={
      "Collaborate with our BIM professionals to develop Revit models for submissions. BIM Collaborate Pro is included in our BIM service packages."
    }
    pathname={location.pathname}
  />
);
